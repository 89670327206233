<template>
  <div class="dropdown dropdown-seasons">
    <button
      v-on:click.prevent="toggleDropdown($event)"
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="seasonsList"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ title }}
    </button>
    <div class="dropdown-menu" aria-labelledby="seasonsList">
      <div class="dropdown-inner">
        <a
          v-for="(item, index) in list"
          :key="index"
          v-on:click.prevent="$emit('select', item, index)"
          class="dropdown-item"
          href="#"
          >{{ textField ? item[textField] : item }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectModal",

  props: {
    title: {
      type: String,
      default: "Seleccioná un valor",
      required: false,
    },
    textField: {
      type: String,
      default: null,
      required: false,
    },
    list: {
      type: Array,
      required: true,
    },
  },

  methods: {
    toggleDropdown(event) {
      var activeDropdown = event.currentTarget.closest(".dropdown");

      //Limpio los otros dropdowns:
      document.querySelectorAll(".dropdown").forEach(function (dropdown) {
        if (dropdown !== activeDropdown) {
          dropdown.classList.remove("show");
        }
      });

      //Toggle del dropdown clickeado:
      activeDropdown.classList.toggle("show");
    },
  },
};
</script>
